import React from "react"
var ImgWidth="auto";
var ImageHeight="auto";
const formImageSrcSet = params => {
  const keys = Object.keys(params.transforms),
    keysLength = keys.length;
  let imageSrcSet = {
    src: params.images[keys[keysLength - 1]],
    srcSet: '',
    sizes: ''
  };
  for (let index = 0; index < keysLength; index++) {
    let width = keys[index].substr(0, keys[index].search(/[a-z]/i));
    imageSrcSet.srcSet += `${params.images[keys[index]]} ${width}w, `
    imageSrcSet.sizes += `${params.transforms[keys[index]]} ${width}px, `
    ImgWidth = keys[keysLength-1].split('x')[0];
    ImageHeight = keys[keysLength-1].split('x')[1];
  }
  return imageSrcSet;
}
console.log(ImgWidth)
console.log(ImageHeight)
const SrcSetImage = ({props}) => {
  if(props.images){
    const ImageSrcSet = formImageSrcSet(props);
    return <img alt={"image place"} {...ImageSrcSet}
                {...props.attr}
                  //  width= {ImgWidth}
                  //  height= {ImageHeight}
                loading="lazy"/>
  }
  return "";
}

export default SrcSetImage;
